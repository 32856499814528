export const excellentIcon = `data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath d='M20,0A20,20,0,1,0,40,20,20.023,20.023,0,0,0,20,0Zm0,37.838A17.835,17.835,0,1,1,37.838,20,17.781,17.781,0,0,1,20,37.838Z'/%3E%3Cpath d='M70.171,100.607a2.119,2.119,0,0,1,2.151,2.151h2.207a4.358,4.358,0,1,0-8.715,0H68.02A2.119,2.119,0,0,1,70.171,100.607Z' transform='translate(-56.969 -85.135)'/%3E%3Cpath d='M171.345,100.607a2.119,2.119,0,0,1,2.151,2.151H175.7a4.358,4.358,0,1,0-8.715,0h2.207A2.119,2.119,0,0,1,171.345,100.607Z' transform='translate(-144.547 -85.135)'/%3E%3Cpath d='M80.892,186.2a12.2,12.2,0,0,0,10.29-5.808L89.4,179.2a10.032,10.032,0,0,1-9.639,4.731,10.138,10.138,0,0,1-7.3-4.731l-1.782,1.2a12.272,12.272,0,0,0,8.841,5.726A11.506,11.506,0,0,0,80.892,186.2Z' transform='translate(-60.93 -155.085)'/%3E%3C/svg%3E%0A`;
export const goodIcon = `data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath d='M20,0A20,20,0,1,0,40,20,20.023,20.023,0,0,0,20,0Zm0,38.261A18.261,18.261,0,1,1,38.261,20,18.281,18.281,0,0,1,20,38.261Z'/%3E%3Cpath d='M101.227,130.613a2.613,2.613,0,1,0-2.613,2.613A2.617,2.617,0,0,0,101.227,130.613Zm-3.484,0a.871.871,0,1,1,.871.871A.873.873,0,0,1,97.742,130.613Z' transform='translate(-85.568 -114.091)'/%3E%3Cpath d='M226.613,128a2.613,2.613,0,1,0,2.613,2.613A2.617,2.617,0,0,0,226.613,128Zm0,3.485a.871.871,0,1,1,.871-.871A.873.873,0,0,1,226.613,131.485Z' transform='translate(-199.659 -114.091)'/%3E%3Cpath d='M121.132,224.222a.871.871,0,0,0-1.23.068,9.691,9.691,0,0,1-14.382,0,.871.871,0,0,0-1.3,1.162,11.433,11.433,0,0,0,16.978,0A.872.872,0,0,0,121.132,224.222Z' transform='translate(-92.711 -199.658)'/%3E%3C/svg%3E%0A`;
export const needsImprovementIcon = `data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' fill='currentColor' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath d='M20,0A20,20,0,1,0,40,20,20.023,20.023,0,0,0,20,0Zm0,38.261A18.261,18.261,0,1,1,38.261,20,18.281,18.281,0,0,1,20,38.261Z'/%3E%3Cpath d='M98.592,133.185A2.592,2.592,0,1,0,96,130.592,2.6,2.6,0,0,0,98.592,133.185Zm0-3.457a.864.864,0,1,1-.864.864A.866.866,0,0,1,98.592,129.728Z' transform='translate(-85.555 -114.074)'/%3E%3Cpath d='M226.592,128a2.592,2.592,0,1,0,2.592,2.592A2.6,2.6,0,0,0,226.592,128Zm0,3.457a.864.864,0,1,1,.864-.864A.866.866,0,0,1,226.592,131.457Z' transform='translate(-199.629 -114.074)'/%3E%3Cpath d='M114.147,224H96.864a.864.864,0,0,0,0,1.728h17.283a.864.864,0,0,0,0-1.728Z' transform='translate(-85.505 -199.645)'/%3E%3C/svg%3E%0A`;
export const arrowBackIcon = `data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EArrow Back%3C/title%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='square' stroke-miterlimit='10' stroke-width='48' d='M244 400L100 256l144-144M120 256h292'/%3E%3C/svg%3E`;
export const bugIcon = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EBug%3C/title%3E%3Cpath d='M370 378c28.89 23.52 46 46.07 46 86M142 378c-28.89 23.52-46 46.06-46 86M384 208c28.89-23.52 32-56.07 32-96M128 206c-28.89-23.52-32-54.06-32-94M464 288.13h-80M128 288.13H48M256 192v256' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/%3E%3Cpath d='M256 448h0c-70.4 0-128-57.6-128-128v-96.07c0-65.07 57.6-96 128-96h0c70.4 0 128 25.6 128 96V320c0 70.4-57.6 128-128 128z' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/%3E%3Cpath d='M179.43 143.52a49.08 49.08 0 01-3.43-15.73A80 80 0 01255.79 48h.42A80 80 0 01336 127.79a41.91 41.91 0 01-3.12 14.3' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/%3E%3C/svg%3E`;
export const chatboxIcon = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EChatbox Ellipses%3C/title%3E%3Cpath d='M408 64H104a56.16 56.16 0 00-56 56v192a56.16 56.16 0 0056 56h40v80l93.72-78.14a8 8 0 015.13-1.86H408a56.16 56.16 0 0056-56V120a56.16 56.16 0 00-56-56z' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/%3E%3Ccircle cx='160' cy='216' r='32'/%3E%3Ccircle cx='256' cy='216' r='32'/%3E%3Ccircle cx='352' cy='216' r='32'/%3E%3C/svg%3E`;
export const documentIcon = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EDocument%3C/title%3E%3Cpath d='M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/%3E%3Cpath d='M256 56v120a32 32 0 0032 32h120' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/%3E%3C/svg%3E`;
export const openLinkIcon = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EOpen%3C/title%3E%3Cpath d='M384 224v184a40 40 0 01-40 40H104a40 40 0 01-40-40V168a40 40 0 0140-40h167.48M336 64h112v112M224 288L440 72' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/%3E%3C/svg%3E`;
export const chatBubblesIcon = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EChatbubbles%3C/title%3E%3Cpath d='M431 320.6c-1-3.6 1.2-8.6 3.3-12.2a33.68 33.68 0 012.1-3.1A162 162 0 00464 215c.3-92.2-77.5-167-173.7-167-83.9 0-153.9 57.1-170.3 132.9a160.7 160.7 0 00-3.7 34.2c0 92.3 74.8 169.1 171 169.1 15.3 0 35.9-4.6 47.2-7.7s22.5-7.2 25.4-8.3a26.44 26.44 0 019.3-1.7 26 26 0 0110.1 2l56.7 20.1a13.52 13.52 0 003.9 1 8 8 0 008-8 12.85 12.85 0 00-.5-2.7z' fill='none' stroke='currentColor' stroke-linecap='round' stroke-miterlimit='10' stroke-width='32'/%3E%3Cpath d='M66.46 232a146.23 146.23 0 006.39 152.67c2.31 3.49 3.61 6.19 3.21 8s-11.93 61.87-11.93 61.87a8 8 0 002.71 7.68A8.17 8.17 0 0072 464a7.26 7.26 0 002.91-.6l56.21-22a15.7 15.7 0 0112 .2c18.94 7.38 39.88 12 60.83 12A159.21 159.21 0 00284 432.11' fill='none' stroke='currentColor' stroke-linecap='round' stroke-miterlimit='10' stroke-width='32'/%3E%3C/svg%3E`;
export const ellipsesIcon = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EEllipsis Horizontal%3C/title%3E%3Ccircle cx='256' cy='256' r='32' fill='none' stroke='currentColor' stroke-miterlimit='10' stroke-width='32'/%3E%3Ccircle cx='416' cy='256' r='32' fill='none' stroke='currentColor' stroke-miterlimit='10' stroke-width='32'/%3E%3Ccircle cx='96' cy='256' r='32' fill='none' stroke='currentColor' stroke-miterlimit='10' stroke-width='32'/%3E%3C/svg%3E`;
export const chatboxEllipsesIcon = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EChatbox Ellipses%3C/title%3E%3Cpath d='M408 64H104a56.16 56.16 0 00-56 56v192a56.16 56.16 0 0056 56h40v80l93.72-78.14a8 8 0 015.13-1.86H408a56.16 56.16 0 0056-56V120a56.16 56.16 0 00-56-56z' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/%3E%3Ccircle cx='160' cy='216' r='32'/%3E%3Ccircle cx='256' cy='216' r='32'/%3E%3Ccircle cx='352' cy='216' r='32'/%3E%3C/svg%3E`;
export const beetleIcon = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%230066CC'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M20 8h-2.81c-.45-.78-1.07-1.45-1.82-1.96L17 4.41 15.59 3l-2.17 2.17C12.96 5.06 12.49 5 12 5c-.49 0-.96.06-1.41.17L8.41 3 7 4.41l1.62 1.63C7.88 6.55 7.26 7.22 6.81 8H4v2h2.09c-.05.33-.09.66-.09 1v1H4v2h2v1c0 .34.04.67.09 1H4v2h2.81c1.04 1.79 2.97 3 5.19 3s4.15-1.21 5.19-3H20v-2h-2.09c.05-.33.09-.66.09-1v-1h2v-2h-2v-1c0-.34-.04-.67-.09-1H20V8zm-6 8h-4v-2h4v2zm0-4h-4v-2h4v2z'/%3E%3C/svg%3E`;
